import React from "react";
import "assets/styles/main.scss";

function App() {
  return (
    <div className="App">
      <header className="App-header">Strona w budowie</header>
    </div>
  );
}

export default App;
